<template>
  <div class="articleList_container">
    <canDragCom v-show="!showSreenshot" @click="screenshot"></canDragCom>
    <div class="articleList_banner">
      <img
        class="articleList_cover"
        :src="require('@/assets/img/art_banner.png')"
      />
      <div class="articleList_search_input">
        <el-input
          v-model.trim="search.key_word"
          placeholder="请输入关键词"
          @keyup.enter="searchData"
          @blur="handlerBlur"
        >
          <template #suffix>
            <el-icon class="el-icon-search" @click="searchData"
              ><Search
            /></el-icon>
          </template>
        </el-input>
      </div>
      <div class="articleList_select">
        <el-select
          v-model.trim="search.periods"
          placeholder="请选择"
          popper-class="select_option"
          @change="changePeriods"
        >
          <el-option label="全部" value="0" />
          <el-option
            v-for="(item, index) in periodsList"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div class="articleList_conent">
      <listScroll
        ref="listScrollRef"
        :url="$urls.literature.list"
        :select="search"
        type="articleList"
      >
        <template v-slot:listItem="{ cItems, cIndex }">
          <articleListItem
            type="articleList"
            :key="cIndex"
            :item="cItems"
            :index="cIndex"
            @click="handleDetail(cItems)"
          ></articleListItem>
        </template>
      </listScroll>

      <div class="articleList_foot_group">
        <img
          @click="handleLike"
          class="articleList_foot_icon_small"
          :src="require('@/assets/img/like.png')"
        />
        <img
          :class="{ refresh_anim: rotate }"
          @click="handleRefresh"
          class="articleList_foot_icon"
          :src="require('@/assets/img/refresh.png')"
        />
        <img
          @click="goToInfo"
          class="articleList_foot_icon_small"
          :src="require('@/assets/img/admin.png')"
        />
      </div>
    </div>

    <screenshot
      v-show="showSreenshot"
      @closeScreenshot="closeScreenshot"
      class="placardCanvasRef"
      ref="screenshotRef"
    ></screenshot>
  </div>
</template>

<script>
import canDragCom from "@/components/unit/canDragCom";
import screenshot from "./screenshot.vue";
import ListScroll from "@/components/unit/ListScroll";
import articleListItem from "./articleListItem.vue";
import { Search } from "@element-plus/icons";
import wx from "@/common/weixin";
import weixin from "weixin-js-sdk";
import "vant/es/image-preview/style";
export default {
  name: "articleList",
  components: {
    ListScroll,
    articleListItem,
    Search,
    canDragCom,
    screenshot,
  },
  data() {
    return {
      search: {
        key_word: "",
        periods: "0",
        page: 0,
      },
      periodsList: [],
      data: [],
      timer: null,
      rotate: false,
      showSreenshot: false,
    };
  },

  async created() {
    this.search.periods =
      this.$route.query.periods ||
      this.$route.query.peroids ||
      this.$route.query.period ||
      this.$route.query.peroid ||
      "0";
    await this.getPeriodData();
    if (this.$tools.getStorage("search_data")) {
      this.search =
        {
          periods: this.search.periods,
          ...this.$tools.getStorage("search_data"),
        } || this.search;
    } else {
      if (this.search.periods == "0") {
        this.search.periods = this.periodsList[0]?.value || "0";
      }
    }
    if (!this.$tools.getStorage("articleData", sessionStorage)) {
      this.$refs.listScrollRef.searchData(this.search);
    } else {
      this.$refs.listScrollRef.initScroll();
    }
    console.log("this.search :>> ", this.search);
  },
  mounted() {
    setTimeout(() => {
      this.initShare();
    }, 1000);
    this.$nextTick(() => {
      //跳转返回 IOS不兼容页面自动刷新问题
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        window.onpageshow = function (event) {
          if (event.persisted) {
            window.location.reload();
          }
        };
      }
    });
  },

  methods: {
    closeScreenshot() {
      this.showSreenshot = false;
    },
    async screenshot() {
      this.showSreenshot = true;
      this.$refs.screenshotRef.uploadShareimg(
        this.search.periods != 0
          ? this.search.periods
          : this.periodsList[0].value
      );
    },

    initShare() {
      let params = {
        title: this.$config.title,
        description: "",
        url: "",
        logo: "https://eye.zhoukan.medflying.com/files/飞派Eye文献领域logo-02.jpg",
      };
      console.log("params :>> ", params);
      weixin.ready(() => {
        wx.sharePageMessage(params);
      });
    },
    searchData(status = true) {
      this.search.page = 0;
      if (status) {
        this.search.periods = "0";
      }
      this.$tools.setStorage("search_data", this.search);
      console.log("this.search :>> ", this.search);
      this.$refs.listScrollRef.searchData(this.search);
    },
    handlerBlur() {
      this.search.periods = "0";
      this.searchData();
    },
    changePeriods() {
      this.$forceUpdate();
      this.search.key_word = "";
      this.searchData(false);
    },
    async getPeriodData() {
      await this.$axios
        .get(this.$urls.literature.periodsList)
        .then((res) => {
          this.periodsList = res.data.map((num) => {
            let year = num.split("-")[0];
            let month = parseInt(num.split("-")[1]);
            return {
              value: num,
              label: `${year}-第${month}期`,
              // label: `${year}-第${this.$tools.intToChinese(month)}期`,
            };
          });
        })
        .catch(() => {});
    },
    handleRefresh() {
      if (this.timer) return;
      this.rotate = true;
      this.search = { key_word: "", periods: "0" };
      this.$refs.listScrollRef.searchData(this.search);

      // this.$refs.listScrollRef.pullingDownHandler(true);
      this.timer = setTimeout(() => {
        this.rotate = false;
        this.timer = null;
      }, 1000);
    },
    handleDetail(item) {
      this.$refs.listScrollRef.saveCurrDate();
      this.$tools.setStorage("search_data", this.search);

      if (item.tag != "幻灯") {
        if (item.is_jump == 0) {
          this.$router.push({
            path: "/articleDetail",
            query: {
              id: item.id,
            },
          });
        } else if (item.is_jump == 1) {
          this.$router.push({
            path: "/customArticleDetail",
            query: {
              id: item.id,
            },
          });
        } else if (item.is_jump == 2) {
          window.location.href = item.literature_url;
        }
      } else {
        let saleLogin = this.$tools.getStorage("saleLogin", localStorage);
        let userLogin = this.$tools.getStorage("userLogin", localStorage);
        //有登陆 双身份 有限专家
        if (userLogin?.phone) {
          this.$router.push({
            path: "/ppt/detail",
            query: {
              id: item.id,
            },
          });
        } else if (saleLogin?.phone) {
          //只销售
          this.$router.push({
            path: "/courseware/preview",
            // path: "/courseware/detail",
            query: {
              id: item.id,
            },
          });
        } else {
          //没有登陆
          this.$router.push({
            path: "/ppt/detail",
            query: {
              id: item.id,
            },
          });
        }
      }
    },
    handleLike() {
      this.$refs.listScrollRef.saveCurrDate();
      this.$tools.setStorage("search_data", this.search);
      let userInfo = this.$tools.getStorage("userLogin", localStorage);
      let saleInfo = this.$tools.getStorage("saleLogin", localStorage);

      //身份共存 专家优先
      if (userInfo?.phone || saleInfo?.phone) {
        this.$router.push({
          path: "/collectList",
        });
      } else {
        this.$router.push({
          path: "/doctorLogin",
          form: this.$route.path,
        });
      }
    },
    goToInfo() {
      this.$refs.listScrollRef.saveCurrDate();
      this.$tools.setStorage("search_data", this.search);

      let userInfo = this.$tools.getStorage("userLogin", localStorage);
      let saleInfo = this.$tools.getStorage("saleLogin", localStorage);

      //身份共存 专家优先
      if (userInfo?.phone) {
        this.$router.push({
          path: "/doctorInfo",
        });
      } else if (saleInfo?.phone) {
        this.$router.push({
          path: "/adminMeet",
        });
      } else {
        this.$router.push({
          path: "/doctorLogin",
          form: this.$route.path,
        });
      }
    },
  },
};
</script>

<style >
.articleList_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.articleList_banner {
  position: relative;
}
.articleList_cover {
  width: 100%;
  height: auto;
}
.articleList_banner .articleList_search_input {
  width: 100%;
  height: 30px;
  padding: 0 20px;
  position: absolute;
  bottom: 170px;
}
.articleList_banner .articleList_search_input .el-input {
  width: 100%;
  height: 30px;
  line-height: normal !important;
}
.articleList_banner .articleList_search_input .el-input__inner {
  padding-left: 2px;
}

.articleList_search_input input {
  height: 30px;
  line-height: normal !important;
  font-size: 14px !important;
  border-radius: 2rem;
}
.articleList_search_input input::-webkit-input-placeholder {
  line-height: normal !important;
  font-size: 14px !important;
  color: #4b4b4b;
}
.articleList_banner .articleList_search_input .el-input__suffix {
  font-size: initial;
  display: flex;
  align-items: center;
  right: 20px;
  color: #4b4b4b;
}
.articleList_banner .el-input__wrapper {
  border: 1px solid #4b4b4b;
  background: border-box;
  color: #4b4b4b;
  border-radius: 18px;
  box-shadow: none !important;
}

.articleList_banner .articleList_select .el-input__inner {
  border: none !important;
}

.articleList_select {
  position: absolute;
  right: 23px;
  width: 130px !important;
  bottom: 110px;
}
.articleList_select .el-select {
  width: 130px !important;
  border-bottom: 1px solid #009d7d;
}
.el-select .el-input .el-select__caret,
.articleList_select .el-input__inner,
.select_option .el-select-dropdown__item.selected {
  color: #009d7d !important;
}
.articleList_conent {
  background: #fff;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  padding: 0 23px;
  margin-top: -85px;
}
.articleList_foot_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.articleList_foot_icon {
  width: 34px;
  height: 34px;
}
.articleList_foot_icon_small {
  width: 25px;
  height: auto;
}
.refresh_anim {
  animation: loop 0.5s linear 0.2s infinite;
}

@keyframes loop {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.articleList_select .el-select__wrapper {
  border: none;
  box-shadow: none !important;
  background: border-box !important;
}
.popup_content_periods {
  width: 80%;
  margin: 20px 0;
}

.articleList_container .listScroll_container {
  margin-bottom: 20px !important;
}
</style>