<template>
  <div
    :style="getScreenshotBtnPos"
    ref="screenshotBtn"
    class="canDragCom_container"
    @click.stop="screenshot"
    @touchmove.stop="touchmove"
  >
    <img
      :src="require('@/assets/images/screenshot/screenshot_btn.png')"
      style="width: 25vw; height: auto"
    />
  </div>
</template>
  <script>
export default {
  name: "canDragCom",
  data() {
    return {
      //按钮位置
      screenshotBtnPos: {
        right: 0,
        top: 0,
      },
      //窗口大小
      windowContainer: {
        width: 375,
        height: 750,
      },
      //按钮高度(px)
      btnHeight: 40,
    };
  },
  computed: {
    getScreenshotBtnPos() {
      return `right:${this.screenshotBtnPos.right}px;top:${this.screenshotBtnPos.top}px;`;
    },
  },
  mounted() {
    setTimeout(() => {
      this.windowContainer = {
        width: 375,
        height: window.innerHeight,
      };
      this.btnHeight = this.$refs.screenshotBtn.clientHeight;
      this.screenshotBtnPos.top = window.innerHeight - 50 - this.btnHeight;
    }, 200);
  },
  methods: {
    screenshot() {
      this.$emit("screenshot");
    },
    // touchstart(event) {
    //     let touche = event.touches[0];
    //     this.screenshotBtnPos = {
    //         right: 0,
    //         top: touche.clientY,
    //     }
    // },
    touchmove(event) {
      event.preventDefault();
      let touche = event.touches[0];
      let top = Math.min(this.windowContainer.height, touche.clientY);
      top = Math.max(top - this.btnHeight, 0);
      this.screenshotBtnPos = {
        right: 0,
        top: top,
      };
    },
    // touchend(event) {
    // // this.scroll.enable();
    // },
  },
};
</script>
  <style>
.canDragCom_container {
  position: absolute;
  z-index: 99999;
}
</style>